div {
	&#out {
		display: table;
		height: 80%;
		width: 100%; }

	&#middle {
		display: table-cell;
		vertical-align: middle; }

	&#in {
		margin-left: auto;
		margin-right: auto;
		width: 50%;

		ul {
			margin-top: 4px;

			li {
				font-size: 1.1em;
				line-height: 1.3em; } } } }
